body {
  background: #ddd;
}

body * {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.ms-Button-flexContainer {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.App {
  text-align: center;
}
