.stoplight {
  .label {
    display: inline;
    margin-left: 4px;
    vertical-align: text-top;
  }
  .backing {
    position: absolute;
    color: black;
    z-index: -1;
    font-size: 20px;
    margin-left: -2px;
    margin-top: -1px;
  }
  .red {
    color: rgb(233, 56, 56);
  }
  .yellow {
    color: rgb(231, 180, 22);
  }
  .green {
    color: rgb(53, 235, 65);
  }
  .none {
    color: rgb(230, 230, 230);
  }
}
