.deviceRecord {
  .stoplight {
    display: inline-block;
    margin-right: 20px;
    // line-height: 0px;
    // font-size: 0xp;
    // right: calc(-100% + 48px);
    // top: -12px;
    // position: relative;
  }
}
