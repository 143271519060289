$grid__bp-xs: 420;
$grid__bp-sm: 480;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;

$mobile-break: 480px;

$padding-desktop: 20px;
$padding-mobile: 16px;
$gutter-desktop: 24px;
$gutter-mobile: 24px;
