.card {
  width: 100%;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 7.2px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  transition-duration: 100ms;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.13) 0px 4px 14.4px 0px,
      rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  }

  &-title {
    line-height: 1rem;
    font-weight: 600;
    font-size: 1rem;
    padding: 0px;
    margin: 0px;

    &.ms-Icon:first-child {
      padding-right: 8px;
    }

    .cardClose {
      float: right;
      color: black;
      top: -8px;
    }

    &-wrapper {
      padding: $padding-desktop;
      margin-bottom: $gutter-desktop;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(232, 232, 232);
      @media (max-width: $mobile-break) {
        padding: $padding-mobile;
        margin-bottom: $gutter-mobile;
      }
    }
  }

  &-content {
    margin: $padding-desktop;
    padding-bottom: $padding-desktop;
    @media (max-width: $mobile-break) {
      margin: $padding-mobile;
      padding-bottom: $padding-mobile;
    }
    .section:not(:last-child) {
      padding-bottom: $padding-desktop;
      @media (max-width: $mobile-break) {
        padding-bottom: $padding-mobile;
      }
    }
  }

  &-cta {
    padding: 12px 0px 12px 16px;
    color: rgb(0, 111, 201);

    &:hover {
      background: rgb(250, 250, 250);
    }
  }
  .collapsed {
    &-content {
      padding-bottom: 0px;
    }
  }
}
