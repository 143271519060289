  .upgrades{
    .tag {
        color: white;
        font-size: 12px;
        border-radius: 15%;
        padding: 2px;
        margin-right: 4px;
        border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  