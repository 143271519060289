// Basic grid system - follows Twitter Bootstrap sizing
// Examples:
//  .container - will need to wrap anything using this grid system
//  .container__row - will start a grid row
//  .container__row--fluid - will create a row that will automatically span the whole width without wrapping.
//  .container__col-<size>-<cols> - will create a grid column, where size is (size: xs,sm,md,lg,xl) and cols is the number of columns (cols: 1 to 12) to span.
//  .hidden-<size>-<direction> - will hide all sizing from size (size: xs,sm,md,lg,xl) and everything up or down (direction: up, down)

$map-grid-props: (
  "": 0,
  "-xs": $grid__bp-xs,
  "-sm": $grid__bp-sm,
  "-md": $grid__bp-md,
  "-lg": $grid__bp-lg,
  "-xl": $grid__bp-xl,
);

$map-container-props: (
  $grid__bp-xs,
  $grid__bp-sm,
  $grid__bp-md,
  $grid__bp-lg,
  $grid__bp-xl
);

@mixin create-container-class($breakpoint) {
  @include create-mq($breakpoint, "min") {
    .container {
      max-width: $breakpoint * 1px;
    }
  }
}

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, "min") {
    &__col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      &__col#{$modifier}-#{$i} {
        flex-basis: calc(100 / ($grid-cols / $i)) * 1%;
      }
      &__col#{$modifier}-offset-#{$i} {
        margin-left: calc(100 / ($grid-cols / $i)) * 1%;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == "") {
    $modifier: "-xs";
  }
  @include create-mq($breakpoint - 1, "max") {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, "min") {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  margin: 0 auto;
  &--fluid {
    display: flex;
    margin: 0;
    max-width: 100%;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}

@each $breakpoint in $map-container-props {
  @include create-container-class($breakpoint);
} ;
