.navbar {
  color: black;
  line-height: 50px;
  padding: 0 20px;
  opacity: 1;
  background-color: whitesmoke;
  border-bottom: 1px solid #aaa;

  &.dev {
    background-color: #a9bdcd
  }
  &.test {
    background-color: #cda9a9;
  }
  &.prod {
    background-color: whitesmoke;
  }

  .headerLogo {
    font-size: 16px;
  }

  .accountLogo {
    line-height: 60px;
    i {
      font-size: 16px;
    }
  }

  .titleLink {
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
  }
}
