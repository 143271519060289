.notifications {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 auto;
  margin-top: 40px;
  padding: 20px;
  .notification {
    margin-bottom: 4px;
  }
}
