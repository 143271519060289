.hostIPTool {
  .listKey {
    font-weight: bold;
    font-size: 12px;
  }
  .listValue {
    font-size: 12px;
  }
  .heading {
    display: block;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .section {
    padding-bottom: 18px;
  }
  .provision {
    width: 100%;
  }
  .copyButton {
  }
}
