.routerRecord {
  .listKey {
    font-weight: bold;
    font-size: 12px;
  }
  .listValue {
    font-size: 12px;
  }
  .heading {
    display: block;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .tag {
    color: white;
    font-size: 12px;
    border-radius: 15%;
    padding: 2px;
    margin-right: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
