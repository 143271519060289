.signal {
  .ms-ProgressIndicator-progressBar {
    background-image: linear-gradient(
      to right,
      rgb(233, 56, 56),
      rgb(231, 180, 22),
      rgb(53, 235, 65)
    ) !important;
    background-attachment: fixed;
  }
}
