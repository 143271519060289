.maintenanceOption {
    text-align: center;
    padding-bottom: 5px;
}
.maintenanceFlushRight {
    float:right;
}

.maintenanceRecord {
    padding-bottom: 24px;
}