#reader__camera_permission_button {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  outline: transparent;
  position: relative;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid rgb(0, 120, 212);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
  user-select: none;
}

#reader__dashboard_section_swaplink {
  cursor: pointer;
}
